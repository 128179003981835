import type { IconType } from '~/components/ui/Icons/Icons';
import SVGIcon from '~/components/ui/Icons/SVGIcon';

export enum Variant {
  PRIMARY = 'bg-primary text-white',
  PRIMARY_LIGHT = 'bg-primary-100 text-primary fo-border-sm border-primary',
  PRIMARY_GHOST = 'bg-white text-primary',
  TERTIARY_GREY_LIGHT = 'bg-grey-100 color-grey-500',
  TERTIARY_GREY_GHOST = 'bg-white color-grey-500',
  ACCENT = 'bg-accent-500 text-white',
  ACCENT_LIGHT = 'bg-accent-100 color-accent-500 fo-border-sm border-accent-500',
  SUCCESS_LIGHT = 'bg-success-100 color-success-700 fo-border-sm border-success-500',
  ERROR_LIGHT = 'bg-error-100 color-error-600 fo-border-sm border-error-500',
  WARNING_LIGHT = 'bg-warning-100 color-warning-600 fo-border-sm border-warning-500',
}

type TProps = {
  variant?: Variant;
  icon?: IconType;
  iconPosition?: 'start' | 'end';
  iconFill?: string;
  text: string;
  extraClasses?: string;
  ribbon?: boolean;
};

const Badge = ({
  variant = Variant.PRIMARY_LIGHT,
  icon,
  iconPosition = 'start',
  iconFill,
  text,
  extraClasses = '',
  ribbon = false,
}: TProps) => {
  return (
    <div className={`badge ${variant} ${extraClasses} ${ribbon ? '-left-wall-large-rounding' : ''}`}>
      <div className={`d-flex ${iconPosition === 'end' ? 'flex-row-reverse' : ''} align-items-center`}>
        {!!icon && (
          <div>
            <SVGIcon
              fill={iconFill}
              iconType={icon}
              className={`${iconPosition === 'end' ? 'ms-1' : 'me-1'} badge-icon`}
              width="16"
              height="16"
            />
          </div>
        )}
        <span className="text-2 -medium">{text}</span>
      </div>
    </div>
  );
};

export default Badge;
